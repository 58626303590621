<template>
  <div class="row">
    <div class="col-md-12">
      <Card :title="'Detail Pengeluaran'">
        <template v-slot:body>
          <div class="row align-items-center">
            <!-- Profile Image -->
            <div class="col-md-4">
              <div
                class="
                  d-flex
                  justify-content-center
                  align-items-center
                  image-container
                "
              >
                <div style="height: 350px; width: 350px; position: relative">
                  <img
                    v-if="data.photo != ''"
                    class="image"
                    :src="data.photo"
                    @load="onImageLoad()"
                  />
                </div>
              </div>
            </div>

            <!-- Table Data -->
            <div class="col">
              <table class="table mb-3">
                <tr>
                  <td><strong>Tanggal</strong></td>
                  <td>{{ data.date_display }}</td>
                </tr>
                <tr>
                  <td style="width: 25%"><strong>Keterangan</strong></td>
                  <td>{{ data.name }}</td>
                </tr>
                <tr>
                  <td><strong>Nominal</strong></td>
                  <td>Rp {{ data.display_amount }}</td>
                </tr>
                <tr>
                  <td><strong>Pembuat</strong></td>
                  <td>{{ data.created_by_name }}</td>
                </tr>
                <tr>
                  <td><strong>Catatan</strong></td>
                  <td>{{ data.notes }}</td>
                </tr>
              </table>

              <button
                @click="
                  $router.push({
                    path: '/pharmacy/pharmacy-expenses/edit/' + data.id,
                  })
                "
                class="btn mx-1 btn-success"
                v-if="manipulateBtn == true"
              >
                <i class="far fa-fw fa-edit"></i> Edit
              </button>
              <button
                @click="deleteData"
                class="btn mx-1 btn-danger"
                v-if="manipulateBtn == true"
              >
                <i class="far fa-fw fa-trash-alt"></i> Hapus
              </button>
            </div>
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import module from "@/core/modules/CrudModule.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  components: {
    Card,
  },

  data() {
    return {
      // Data
      data: {},
      // Other
      imgLoaded: false,
      manipulateBtn: false,
    };
  },

  methods: {
    onImageLoad() {
      this.imgLoaded = true;
    },

    async deleteData() {
      let result = await module.delete(
        "pharmacy-expenses/" + this.$route.params.id
      );
      // If Deleted
      if (result) {
        // Redirect To List
        this.$router.push("/pharmacy/pharmacy-expenses/list");
      }
    },

    async get() {
      this.data = await module.get(
        "pharmacy-expenses/" + this.$route.params.id
      );
      // If Data Not Found
      if (this.data == null) {
        // Redirect To List
        this.$router.push("/pharmacy/pharmacy-expenses/list");
      }
    },

    // access management
    async setActiveMenu() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);

      let a;
      for (a = 0; a < access_right.length; a++) {
        ;

        if (access_right[a] == "1404") {
          this.manipulateBtn = true;
        }
      }
    },
  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Apotek", route: "/pharmacy/dashboard" },
      { title: "Pengeluaran", route: "/pharmacy/pharmacy-expenses/list" },
      { title: "Detail Pengeluaran" },
    ]);
    // Get Data
    this.get();
    this.setActiveMenu();
  },
};
</script>

<style>
</style>